// Msal imports
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { loginRequest } from './azureAdB2cServiceConfig';
//import Error500Page from "app/main/errors/500/Error500Page";

export const MsalAuthComponent=(Component:React.FunctionComponent)=> {
    const authRequest = {
        ...loginRequest
    };
    return (
        <MsalAuthenticationTemplate 
            interactionType={InteractionType.Redirect} 
            authenticationRequest={authRequest} 
            //errorComponent={Error500Page}
        >
            <Component />
        </MsalAuthenticationTemplate>
      )
  };

  