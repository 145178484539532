import React, { useMemo, useState } from 'react'
import { Route, Routes, BrowserRouter } from 'react-router-dom'
import { MsalProvider } from '@azure/msal-react';
import * as Sentry from "@sentry/react";
import './App.scss'

import { msalInstance } from './utils/azureAdB2cService/msalSetup';
import { MsalAuthComponent } from './utils/azureAdB2cService/MsalAuthComponent';

import {
	QueryClient,
	QueryClientProvider,
  } from "@tanstack/react-query";
import { ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import PageLoader from './app/components/shared/PageLoader';
import ErrorBoundary from './app/components/shared/ErrorBoundary'
import HomePage from './app/pages/dashboard/HomePage';
import { AppContext } from './utils/AppContext';
import { DeadLetterContext, SelectableInterface } from './utils/typings/Shared';
import ApplicationDetails from './app/pages/applications/ApplicationDetails';
import { ProposedFilingQuery } from './utils/typings/Applications';

const NotFoundComponent = React.lazy(() => import('./app/components/shared/NotFoundComponent'))
const DeadLetterApplications = React.lazy(() => import('./app/pages/applications/DeadLetterQueueApplications'))
const OfflineApplications = React.lazy(() => import('./app/pages/applications/OfflineApplications'))
const Applications = React.lazy(() => import('./app/pages/applications/Applications'))
const Notifications = React.lazy(() => import('./app/pages/notifications/Notifications'))
const AtRiskApplications = React.lazy(() => import('./app/pages/applications/AtRiskApplications'))
const QueriedApplications = React.lazy(() => import('./app/pages/applications/QueriedApplications'))
const EditApplication = React.lazy(() => import('./app/pages/applications/EditApplication'))
// const ApplicationDetails = React.lazy(() => import('./app/pages/applications/ApplicationDetails'))
const UnpaidApplicationDetails = React.lazy(() => import('./app/pages/applications/UnpaidApplicationPreview'))
const ClientProfileApplications = React.lazy(() => import('./app/pages/applications/ClientProfileApplications'))
const OpexPipelines = React.lazy(() => import('./app/pages/opex/OpexPipeline'))
const OpexMetrics = React.lazy(() => import('./app/pages/opex/OpexMetrics'))
const RevenueTracking = React.lazy(() => import('./app/pages/reporting/RevenueTracking'))
const Dashboard = React.lazy(() => import('./app/pages/dashboard/Dashboard'))
const Operations = React.lazy(() => import('./app/pages/operations/Operations'))
const OperationsDetails = React.lazy(() => import('./app/pages/operations/OperationsDetails'))
const Partners = React.lazy(() => import('./app/pages/partners/Partners'))
const PartnersDetails = React.lazy(() => import('./app/pages/partners/PartnerDetails'))
const ApplicationsReporting = React.lazy(() => import('./app/pages/reporting/ApplicationsReporting'))
const OperationsReporting = React.lazy(() => import('./app/pages/reporting/OperationsReporting'))
const PaymentBotLogs = React.lazy(() => import('./app/pages/reporting/PaymentBotLogs'))
const Customers = React.lazy(() => import('./app/pages/reporting/Customers'))
const Pricing = React.lazy(() => import('./app/pages/settings/Pricing'))
const PricingDetails = React.lazy(() => import('./app/pages/settings/PricingDetails'))
const Groups = React.lazy(() => import('./app/pages/settings/Groups'))
const Holidays = React.lazy(() => import('./app/pages/settings/Holidays'))
const PartnerAssignments = React.lazy(() => import('./app/pages/settings/PartnerAssignmentsConfig'))
const Validations = React.lazy(() => import('./app/pages/settings/Validations'))

const DashboardComponent = () => {
	return MsalAuthComponent(Dashboard);
};
const OfflineApplicationsComponent = () => {
	return MsalAuthComponent(OfflineApplications);
};
const DeadLetterComponent = () => {
	return MsalAuthComponent(DeadLetterApplications);
};
const ApplicationsComponent = () => {
	return MsalAuthComponent(Applications);
};
const NotificationsComponent = () => {
	return MsalAuthComponent(Notifications);
};
const AtRiskApplicationsComponent = () => {
	return MsalAuthComponent(AtRiskApplications);
};
const QueryManagementComponent = () => {
	return MsalAuthComponent(QueriedApplications);
};
const EditApplicationComponent = () => {
	return MsalAuthComponent(EditApplication);
};
const ClientApplicationsComponent = () => {
	return MsalAuthComponent(ClientProfileApplications);
};
const ApplicationDetailsComponent = () => {
	return MsalAuthComponent(ApplicationDetails);
};
const UnpaidApplicationDetailsComponent = () => {
	return MsalAuthComponent(UnpaidApplicationDetails);
};
const OpexPipelineComponent = () => {
	return MsalAuthComponent(OpexPipelines);
};
const OpexMetricsComponent = () => {
	return MsalAuthComponent(OpexMetrics);
};
const RevenueTrackingComponent = () => {
	return MsalAuthComponent(RevenueTracking);
};
const OperationsComponent = () => {
	return MsalAuthComponent(Operations);
};
const OperationsDetailsComponent = () => {
	return MsalAuthComponent(OperationsDetails);
};
const PartnersComponent = () => {
	return MsalAuthComponent(Partners);
};
const PartnersDetailsComponent = () => {
	return MsalAuthComponent(PartnersDetails);
};
const ReportingComponent = () => {
	return MsalAuthComponent(ApplicationsReporting);
};
const OperationsReportingComponent = () => {
	return MsalAuthComponent(OperationsReporting);
};
const PaymentBotLogsComponent = () => {
	return MsalAuthComponent(PaymentBotLogs);
};
const CustomerComponent = () => {
	return MsalAuthComponent(Customers);
};
const PricingComponent = () => {
	return MsalAuthComponent(Pricing);
};
const PricingDetailsComponent = () => {
	return MsalAuthComponent(PricingDetails);
};
const GroupsComponent = () => {
	return MsalAuthComponent(Groups);
};
const HolidayComponent = () => {
	return MsalAuthComponent(Holidays);
};
const PartnerAssignmentsComponent = () => {
	return MsalAuthComponent(PartnerAssignments);
};
const ValidationsComponent = () => {
	return MsalAuthComponent(Validations);
};

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			staleTime: 45*(60*1000), // 45 mins
			cacheTime: 50*(60*1000), // 50 mins
			refetchOnWindowFocus: false,
			retry: false,
		},
	},
})



function App() {
	// This is not being used currently - ignore
	// const [eventId, setEventId] = useState<string>('');
	// const data = {
	// 	setEvent: (id:string) => {
	// 		setEventId(id);
	// 	},
	// 	eventId,
	// };
	const [deadLetterData, setDeadLetterData] = useState<DeadLetterContext>({
		selectedRows: [],
		allSelected: false,
		selectedCount: 0
	})
	const [queryTypes, setQueryTypes] = useState<string[]>([])
	const [proposedQueryDetails, setProposedQueryDetails] = useState<ProposedFilingQuery>({
		id: '',
		applicationId: '',
		applicationName: '',
		companyName: '',
		description: '',
		queryType: [],
		approvedBy: '',
		isApproved: false,
		dateCreated: '',
		dateUpdated: '',
		companyId: '',
		dateApproved: '',
		customerEmail: '',
		applicationQueryIds: [],
		applicationQueryCount: 0,
		approvedQueryTypes: []
	})

	const contextData = useMemo(() => ({
		setSelectedDeadLetterRows: ({selectedRows, allSelected, selectedCount}:SelectableInterface) => setDeadLetterData({
			...deadLetterData,
			selectedCount,
			allSelected,
			selectedRows
		}),
		deadLetterData,
		queryManagement: {
			queryTypes,
			setQueryTypes: (type:string[]) => setQueryTypes(type),
			proposedQueryDetails,
			setProposedQueryDetails: (details:ProposedFilingQuery) => setProposedQueryDetails(details)
		}
	}), [deadLetterData, queryTypes, proposedQueryDetails])

	window.addEventListener('vite:preloadError', (event) => {
		window.location.reload()
	})

	return (
		<Sentry.ErrorBoundary fallback={(error) => <ErrorBoundary error={error}/>} showDialog>
			<MsalProvider instance={msalInstance}>
				<AppContext.Provider value={contextData}>
					<React.Suspense fallback={<PageLoader/>} >
						<BrowserRouter>
							<ToastContainer
								position="top-right"
								hideProgressBar={true}
								autoClose={2000}
								newestOnTop={true}
								closeOnClick
								pauseOnHover
								theme="colored"
								transition={Slide}
							/>
							<QueryClientProvider client={queryClient}>
								<Routes>
									<Route path='/' element={<DashboardComponent/>}>
										<Route index element={<HomePage/>} />
										<Route path='notifications' element={<NotificationsComponent/>} />
										<Route path='deadletter' element={<DeadLetterComponent/>} />
										<Route path='offline' element={<OfflineApplicationsComponent/>} />
										<Route path='applications' element={<ApplicationsComponent/>} />
										<Route path='applications/:id' element={<ApplicationDetailsComponent/>}/>
										<Route path='unpaidapplication/:id' element={<UnpaidApplicationDetailsComponent/>}/>
										<Route path='clientapplications/:id' element={<ClientApplicationsComponent/>}/>
										<Route path='riskApplications' element={<AtRiskApplicationsComponent/>}/>
										<Route path='queryManagement' element={<QueryManagementComponent/>}/>
										<Route path='edit/:id' element={<EditApplicationComponent/>}/>
										<Route path='opex/pipeline' element={<OpexPipelineComponent/>}/>
										<Route path='opex/metrics' element={<OpexMetricsComponent/>}/>
										<Route path='revenue' element={<RevenueTrackingComponent/>}/>
										<Route path='operations' element={<OperationsComponent/>}/>
										<Route path='operations/:userId' element={<OperationsDetailsComponent/>}/>
										<Route path='operationsReporting' element={<OperationsReportingComponent/>}/>
										<Route path='paymentBotReporting' element={<PaymentBotLogsComponent/>}/>
										<Route path='partners' element={<PartnersComponent/>}/>
										<Route path='partners/:userId' element={<PartnersDetailsComponent/>}/>
										<Route path='reporting' element={<ReportingComponent/>}/>
										<Route path='customers' element={<CustomerComponent/>}/>
										<Route path='pricing' element={<PricingComponent/>}/>
										<Route path='pricing/:applicationType' element={<PricingDetailsComponent/>}/>
										<Route path='groups' element={<GroupsComponent/>}/>
										<Route path='holidays' element={<HolidayComponent/>}/>
										<Route path='assignments' element={<PartnerAssignmentsComponent/>}/>
										<Route path='validations' element={<ValidationsComponent/>}/>
									</Route>
									<Route path="*" element={<NotFoundComponent />} />
								</Routes>
							</QueryClientProvider>
						</BrowserRouter>
					</React.Suspense>
				</AppContext.Provider>
			</MsalProvider>
		</Sentry.ErrorBoundary>
	)
}

export default App;
