import axios, { InfraAxiosInstance, operationsBaseUrl } from "../../utils/AxiosConfig";
import { loggerClient } from "../../utils/LoggerClient";
import { correlationId, userData, userGroups } from "../../utils/data/Shared";
import { Holiday, HolidayMini, PartnerAssignmentData, ProficiencyCertificateTriggerData } from "../../utils/typings/Settings";



export const fetchPricingByCountry = async (country:string): Promise<any> => {
    let response = await InfraAxiosInstance.get(`norebase-price/${country}`, {
        headers: {
            ApiTemplate: '/norebase-price/${country}',
            StartTime: Date.now()
        }
    })
    return response.data
};

export const updatePricing = async (data:any, formerPrice?:string): Promise<any> => {
    return InfraAxiosInstance.put(`norebase-price`, data, {
        headers: {
            ApiTemplate: '/norebase-price',
            StartTime: Date.now()
        }
    })
    .then(response => {
        if(formerPrice){
            loggerClient.trackEvent('OperationsPricingUpdate', { userGroups, formerRate:formerPrice, newRate:data.localExchangeRateForUsd, email: userData.email, userId: userData.userId}, correlationId, 'POST')
        }
        return response.data
    }).catch((error:{ response: { data: { message: string }}}) => {
        return Promise.reject(new Error(error.response.data.message))
    })
};

export const addUserToGroup = async (data:any): Promise<any> => {
    let response = await axios.post(`User/add-user-to-group`, data, {
        headers: {
            ApiTemplate: '/User/add-user-to-group',
            StartTime: Date.now()
        }
    })
    return response.data
};

export const removeUserFromGroup = async (data:any): Promise<any> => {
    let response = await axios.put(`User/remove-user-from-group`, data, {
        headers: {
            ApiTemplate: '/User/remove-user-from-group',
            StartTime: Date.now()
        }
    })
    return response.data
};

export const fetchHolidays = async ({ token } : { token: string}): Promise<any> => {
    const url = new URL(`${operationsBaseUrl}NationalBankHoliday`)

    url.searchParams.set('pageSize', '100')

    if(token){
        url.searchParams.set('continuationToken', token)
    }
    const parseUrl = url.toString()
    const urlIndex = parseUrl.indexOf(`/api/`) + 5
    const urlResult = parseUrl.substring(urlIndex)

    let response = await axios.get(`${urlResult}`, {
        headers: {
            ApiTemplate: '/NationalBankHoliday',
            StartTime: Date.now()
        }
    })
    return response.data.result
};

export const createHoliday = async (data:Holiday): Promise<any> => {
    let response = await axios.post(`NationalBankHoliday`, data, {
        headers: {
            ApiTemplate: '/NationalBankHoliday',
            StartTime: Date.now()
        }
    })
    return response.data
};

export const updateHoliday = async (data: Holiday): Promise<any> => {
    let response = await axios.put(`NationalBankHoliday/${data.id}`, data, {
        headers: {
            ApiTemplate: '/NationalBankHoliday/${data.id}',
            StartTime: Date.now()
        }
    })
    return response.data
};

export const deleteHoliday = async (data: HolidayMini): Promise<any> => {
    let response = await axios.delete(`NationalBankHoliday/${data.country}/${data.id}`, {
        headers: {
            ApiTemplate: '/NationalBankHoliday/${data.country}/${data.id}',
            StartTime: Date.now()
        }
    })
    return response.data
};

export const getPartnerAssignments = async (): Promise<any> => {
    let response = await axios.get(`RoleManager/get-partner-assignment`, {
        headers: {
            ApiTemplate: '/RoleManager/get-partner-assignment',
            StartTime: Date.now()
        }
    })
    return response.data.result
};

export const addPartnerAssignment = async (data:PartnerAssignmentData): Promise<any> => {
    let response = await axios.post(`RoleManager/assign-partner`, data, {
        headers: {
            ApiTemplate: '/RoleManager/assign-partner',
            StartTime: Date.now()
        }
    })
    return response.data.result
};

export const updatePartnerAssignment = async (id:string, data:PartnerAssignmentData): Promise<any> => {
    let response = await axios.put(`RoleManager/update-partner-assignment/${id}`, data, {
        headers: {
            ApiTemplate: '/RoleManager/update-partner-assignment/${id}',
            StartTime: Date.now()
        }
    })
    return response.data.result
};

export const deletePartnerAssignment = async (id:string): Promise<any> => {
    let response = await axios.delete(`RoleManager/delete-partner-assignment/${id}`, {
        headers: {
            ApiTemplate: '/RoleManager/delete-partner-assignment/${id}',
            StartTime: Date.now()
        }
    })
    return response.data.result
};

export const getRestrictedWords = async (): Promise<any> => {
    let response = await axios.get(`CacRestrictedWords`, {
        headers: {
            ApiTemplate: '/CacRestrictedWords',
            StartTime: Date.now()
        }
    })
    return response.data.result
};

export const addRestrictedWords = async (word: string): Promise<any> => {
    let response = await axios.put(`CacRestrictedWords/add-to-list/${word}`,
    {
        headers: {
            ApiTemplate: '/CacRestrictedWords/add-to-list/${word}',
            StartTime: Date.now()
        }
    })
    return response.data.result
};

export const deleteRestrictedWords = async (word:string): Promise<any> => {
    let response = await axios.delete(`CacRestrictedWords/remove-from-list/${word}`,
    {
        headers: {
            ApiTemplate: '/CacRestrictedWords/remove-from-list/${word}',
            StartTime: Date.now()
        }
    })
    return response.data.result
};


export const getProficiencyCertTriggers = async (): Promise<any> => {
    let response = await InfraAxiosInstance.get(`ProficiencyCertTrigger`, {
        headers: {
            ApiTemplate: '/ProficiencyCertTrigger',
            StartTime: Date.now()
        }
    })
    return response.data.result
};
export const addProficiencyCertTrigger = async (word: ProficiencyCertificateTriggerData): Promise<any> => {
    let response = await InfraAxiosInstance.post(`ProficiencyCertTrigger`, word,
        {
            headers: {
                ApiTemplate: '/ProficiencyCertTrigger',
                StartTime: Date.now()
            }
        })
    return response.data.result
};

export const deleteProficiencyCertTrigger = async (word: ProficiencyCertificateTriggerData): Promise<any> => {
    let response = await InfraAxiosInstance.post(`ProficiencyCertTrigger/${word.id}/delete`,
        {
            headers: {
                ApiTemplate: '/ProficiencyCertTrigger/${word.id}/delete',
                StartTime: Date.now()
            }
        })
    return response.data.result
};