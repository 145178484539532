import axios, { operationsBaseUrl } from "../../utils/AxiosConfig";
import { AddLawyerCredentialsPayload } from "../../utils/typings/Api";
import { CreateUserAccount, PartnerDecisionInterface, RemoveUserType } from "../../utils/typings/Partners";


export const fetchPartners = async ({ status, user}: any): Promise<any> => {
    let response = await axios.get(`Account/get-users/${status}?partnerType=${user}`, {
        headers: {
            ApiTemplate: '/Account/get-users/${status}?partnerType=${user}',
            StartTime: Date.now()
        }
    })
    return response.data.result.data
};

export const fetchPartnerDetails = async (email:string): Promise<any> => {
    let response = await axios.get(`Account/get-profile/${email}`, {
        headers: {
            ApiTemplate: '/Account/get-profile/${email}',
            StartTime: Date.now()
        }
    })
    return response.data.result
};

export const AddUser = async (data:CreateUserAccount): Promise<any> => {
    let response = await axios.post(`Account/create-user-account`, data, {
        headers: {
            ApiTemplate: '/Account/create-user-account',
            StartTime: Date.now()
        }
    })
    return response.data
};

export const removeUser = async (data: RemoveUserType): Promise<any> => {
    let response = await axios.put(`User/remove-user-from-group`, data, {
        headers: {
            ApiTemplate: '/User/remove-user-from-group',
            StartTime: Date.now()
        }
    })
    return response.data.result
};

export const updateProfile = async (data:CreateUserAccount): Promise<any> => {
    let response = await axios.put(`Account/update-profile`, data, {
        headers: {
            ApiTemplate: '/Account/update-profile}',
            StartTime: Date.now()
        }
    })
    return response.data.result
};

export const fetchPartnerApplications = async (email:string): Promise<any> => {
    const url = new URL(`${operationsBaseUrl}OperationsAdmin/partner-applications/${email}`)
    url.searchParams.set('pageSize', '150')
    url.searchParams.set('page', '1')

    const parseUrl = url.toString()
    const urlIndex = parseUrl.indexOf(`/api/`) + 5
    const urlResult = parseUrl.substring(urlIndex)

    let response = await axios.get(`${urlResult}`, {
        headers: {
            ApiTemplate: '/OperationsAdmin/partner-applications/${email}',
            StartTime: Date.now()
        }
    })
    return response.data.result.data
};

export const partnerAction = async (data: PartnerDecisionInterface): Promise<any> => {
    let response = await axios.put(`Account/verify-profile/${data.profileId}/${data.emailAddress}/${data.status}`, '', {
        headers: {
            ApiTemplate: '/Account/verify-profile/${data.profileId}/${data.emailAddress}/${data.status}',
            StartTime: Date.now()
        }
    })
    return response.data.result
};

export const fetchPartnerServices = async (partnerEmail: string) => {
    let response = await axios.get(`ServiceTimelineManager/assigned-timelines/${partnerEmail}`, {
        headers: {
            ApiTemplate: '/ServiceTimelineManager/assigned-timelines/${partnerEmail}',
            StartTime: Date.now()
        }
    });
    return response.data.result
};

export const sendLawyerCredentials = async (data: AddLawyerCredentialsPayload) => {
    return axios.post(`Rolemanager/lawyer-credentials`, data, {
        headers: {
            ApiTemplate: '/Rolemanager/lawyer-credentials',
            StartTime: Date.now()
        }
    })
    .then((response: { data: { result: string; }; }) => {
        return response.data.result
    }).catch((error:{ response: { data: { message: string }}}) => {
        return Promise.reject(error.response.data.message)
    })
};

export const suspendLawyerCredentials = async (data: AddLawyerCredentialsPayload) => {
    return axios.put(`Rolemanager/lawyer-credentials/suspend`, data, {
        headers: {
            ApiTemplate: '/Rolemanager/lawyer-credentials/suspend',
            StartTime: Date.now()
        }
    })
    .then((response: { data: { result: string; }; }) => {
        return response.data
    }).catch((error:{ response: { data: { message: string }}}) => {
        return Promise.reject(new Error(error.response.data.message))
    })
};

export const fetchLawyerCredentials = async ():Promise<AddLawyerCredentialsPayload[]> => {
    let response = await axios.get(`RoleManager/lawyer-credentials`, {
        headers: {
            ApiTemplate: '/RoleManager/lawyer-credentials',
            StartTime: Date.now()
        }
    });
    return response.data.result
};

export const fetchApplicationsCredentials = async (applicationId:string):Promise<{lawyerId:string, lawyerUsername: string}> => {
    let response = await axios.get(`OrderManager/get-cac-lawyer/${applicationId}`, {
        headers: {
            ApiTemplate: '/OrderManager/get-cac-lawyer/${applicationId}',
            StartTime: Date.now()
        }
    });
    return response.data.result
};