import React, { useEffect, useState } from 'react'
import { HiChevronLeft } from 'react-icons/hi'
import { CiUser } from 'react-icons/ci'
import { TiArrowSortedDown } from 'react-icons/ti'
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { actionOptions, applicationDetailsTabs } from '../../../utils/data/ApplicationDetails'
import { useApplicationInfoData } from '../../hooks/useApplicationDetailsQuery';
import { toast } from 'react-toastify';
import Tippy from '@tippyjs/react';
import ApplicationTab from '../../components/shared/Tab'
import Loader from '../../components/ui/Loader';
import { loggerClient } from '../../../utils/LoggerClient';
import { correlationId } from '../../../utils/data/Shared';
import ErrorComponent from '../../components/shared/ErrorComponent'
import ChangeApplicationStatus from '../../components/modals/applications/ChangeApplicationStatusModal'
import ApplicationPreview from '../../components/pages/applications/ApplicationPreview'

const ApplicationQueries = React.lazy(() => import('../../components/pages/applications/ApplicationQueries'))
const ApplicationDocuments = React.lazy(() => import('../../components/pages/applications/ApplicationDocuments'))
const AddPaymentDetailsModal = React.lazy(() => import('../../components/modals/applications/AddPaymentDetailsModal'))
const ApplicationServiceTimeline = React.lazy(() => import('../../components/pages/applications/ApplicationServiceTimeline'))
const CACCredentials = React.lazy(() => import('../../components/pages/applications/CACCredentials'))
const BotHistory = React.lazy(() => import('../../components/pages/applications/BotHistory'))


function ApplicationDetails() {


    const { id } = useParams()
    const navigate = useNavigate()
    const location = useLocation()
    const [searchParams] = useSearchParams()


    const [activeComponentTab, setActiveComponentTab] = useState<string>("")
    const [showPaymentDetailsModal, setShowPaymentDetailsModal] = useState<boolean>(false)
    const [showChangeStatusModal, setShowChangeStatusModal] = useState<boolean>(false)


    useEffect(() => {
        //SHOW QUERIES TAB BY DEFAULT IF COMING FROM QUERY REPORT PAGE
        if(location?.state?.tab){
            setActiveComponentTab('Queries')
        }
        else{
            setActiveComponentTab("Details")
        }
    }, [location])
    

    const changeTabComponent = (value: string) => {
        setActiveComponentTab(value)
    }


    const goBack = () => {
        navigate(-1)
    }


    const { isLoading, isError, data } = useApplicationInfoData({ enable: 'default' })



    const navigateToClientProfile = () => {
        navigate(
            `/clientapplications/${data?.order?.metaData?.userId}`, 
            { 
                state: { 
                    customerEmail: data?.customerEmail,
                    companyId: data?.order?.metaData?.companyId,
                    from: data?.order?.title
                } 
            }
        )
    }

    
    
    const handleActionSelection = (dataInfo:string) => {
        if(dataInfo === 'Payment'){
            setShowPaymentDetailsModal(true)
        }
        else if(dataInfo === 'ChangeStatus'){
            const userGroups = JSON.parse(localStorage.getItem('userGroups')!)
            const userData = JSON.parse(localStorage.getItem('userData')!)
            if(!userGroups.includes('Reviewers')){
                toast.warning('You are not authorized to change application status')
                loggerClient.trackEvent('UnauthorizedStatusUpdate', { applicationName:data?.order?.title, applicationId: data?.order?.applicationId, userGroups, email: userData.email, userId: userData.userId}, correlationId, 'POST')
                return false
            }
            setShowChangeStatusModal(true)
        }
        else if(dataInfo === 'edit'){
            navigate(
                `/edit/${id}?companyId=${searchParams.get('companyId')}&customerEmail=${searchParams.get('customerEmail')}`, 
                { 
                    state: { 
                        customerEmail: searchParams.get('customerEmail'),
                        companyId: searchParams.get('companyId'),
                        queryResolveData: null
                    } 
                }
            )
        }
    }


    const actionPopup = ():JSX.Element => (
        <div className='rounded-md bg-white shadow-2xl w-58 relative z-10'>
            {
                actionOptions.map(option => (
                    <div onClick={() => handleActionSelection(option.tab)} key={option.title} className='px-4 flex items-center gap-5 cursor-pointer border-b border-lightGray hover:bg-lightBlue'>
                        { option.icon }
                        <p className='text-sm py-3'>{ (data?.order?.currentStatus === 'Completed' || data?.order?.currentStatus === 'InCorporated') && option.title === 'Close Transaction' ? 'Edit Incorporation Documents' : option.title }</p>
                    </div>
                ))
            }
        </div>
    )


    return (
        <>
            { showChangeStatusModal && <ChangeApplicationStatus open={showChangeStatusModal} onClose={() => setShowChangeStatusModal(false)}/>}
            { showPaymentDetailsModal && <AddPaymentDetailsModal open={showPaymentDetailsModal}  onClose={() => setShowPaymentDetailsModal(false)}/>}
            {
                isLoading && <Loader className='my-9'/>
            }
            {
                !isLoading && !isError &&
                <div className='px-6 xl:px-14'>
                    <div className='flex gap-3 my-9 text-sm'>
                        <button onClick={goBack} className='cursor-pointer flex gap-3 items-center'>
                            <HiChevronLeft size={20}/>
                            <span>Back</span>
                        </button>
                        <div className='font-semibold flex gap-3 items-center'>
                            <HiChevronLeft size={20}/>
                            <span>{ data?.order?.title }</span>
                        </div>
                    </div>
                    <div className='flex flex-wrap gap-5 my-9'>
                        <h1 className='font-semibold text-xl'>{ data?.order?.title }</h1>
                        <div className='flex gap-2 items-center cursor-pointer'>
                            <CiUser className='text-blue' size={20}/>
                            <button onClick={navigateToClientProfile} className='text-sm text-blue'>View Client Profile</button>
                        </div>
                    </div>
                </div>
            }
            {
                isError && !isLoading && 
                <ErrorComponent className='py-9' text='Application info failed to load!' />
            }
            <div className='flex flex-col md:flex-row md:items-center gap-5 justify-between border-t border-b border-lightGray py-4 px-6 xl:px-14'>
                <ApplicationTab tabs={applicationDetailsTabs} onClick={changeTabComponent} activeComponentTab={activeComponentTab} setactiveComponentTab={setActiveComponentTab} />
                <div className='flex gap-5'>
                    <Tippy content={actionPopup()} interactive={true} trigger='click' placement='bottom-end' zIndex={10}>
                        <div className='bg-teal px-5 py-3 text-white rounded-md flex gap-2 items-center cursor-pointer self-end md:self-auto'>
                            <span>Manage application</span>
                            <TiArrowSortedDown size={20}/>
                        </div>
                    </Tippy>
                </div>
            </div>
            <div className='px-6 xl:px-14'>
                {activeComponentTab === 'Details' && <ApplicationPreview />}
                {activeComponentTab === 'Queries' && <ApplicationQueries/>}
                {activeComponentTab === 'Documents' && <ApplicationDocuments />}
                {activeComponentTab === 'Process' && <ApplicationServiceTimeline />}
                {activeComponentTab === 'CAC Credentials' && <CACCredentials />}
                {activeComponentTab === 'Bot History' && <BotHistory />}
            </div>
        </>
    )
}

export default ApplicationDetails