import { FlagInterface, ModalInterface } from '../../../../utils/typings/Applications'
import dayjs from 'dayjs'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'
import ModalWrapper from '../../shared/ModalWrapper'
import Loader from '../../ui/Loader'

function ViewFlagHistoryModal({ open, onClose, flagHistory, isLoading }:Readonly<ModalInterface> ) {

    dayjs.extend(LocalizedFormat)

    if(!open) return null
    return (
        <ModalWrapper onClose={onClose}>
            <div className='mb-10'>
                <h3 className='font-semibold text-lg'>Flag History</h3>
            </div>
            {
                isLoading && <Loader/>
            }
            {
                flagHistory?.length > 0 &&
                flagHistory?.map((flag:FlagInterface) => (
                    <div key={flag.comment} className='border border-lightGray rounded-md p-4 flex flex-col gap-3 mb-4'>
                        <p className='text-sm text-gray'>Date Created: <span className='text-base text-black'>{ dayjs(flag.createdDate).format('LLL')}</span></p>
                        { flag.comment && <p className='text-sm text-gray'>Comment: <span className='text-base text-black'>{ flag.comment }</span></p>}
                        { flag.flaggedBy && <p className='text-sm text-gray'>Created By: <span className='text-base text-black'>{ flag.flaggedBy }</span></p>}
                        { flag?.commentBy && <p className='text-sm text-gray'>Created By: <span className='text-base text-black'>{ flag.commentBy }</span></p> }
                    </div>
                ))
            }
            {
                flagHistory?.length === 0 &&
                <div className='mx-auto text-center'>
                    <img src='./empty.png' className='mx-auto'/>
                    <p className='mt-10'>No data to display</p>
                </div>
            }
        </ModalWrapper>
    )
}

export default ViewFlagHistoryModal