import { MdError } from 'react-icons/md'



type errorType = {
    error?: {
        error: {
            message: string
        }
    }
}


const ErrorMessage = () => {

    return (
        <div className='text-center h-screen flex items-center justify-center max-w-xs md:max-w-xl m-auto'>
            <div className='border-lightGray px-6 py-6 border rounded-md flex items-center justify-center flex-col'>
                <div>
                    <div className=''>
                        <MdError size={60} className='text-red mx-auto'/>
                    </div>
                    <h2 className='text-xl lg:text-2xl font-semibold mt-10'>Something went wrong</h2>
                    <p className='mt-3 mb-5 md:w-72 text-center mx-auto'>Please report your experience on the slack channel to enable us to debug.</p>
                </div>
            </div>
        </div>
    )
}


const ErrorBoundary = ({ error }:errorType) => {
    // const routeError = useRouteError() as routeErrorType;
    // console.log(routeError?.message)
    console.log(error)


    const potentialErrors = [
        'Failed to fetch dynamically imported module',
        'Unable to preload CSS',
        'error loading dynamically imported module'
    ];


    let isPotentialError = potentialErrors.some(e => error?.error?.message?.includes(e))


    if(isPotentialError){
        setTimeout(() => {
            location.reload()
        }, 3000);
        return  <div className='text-center h-screen flex items-center justify-center max-w-xs md:max-w-xl m-auto'>
                    <div className='border-lightGray px-6 py-6 border rounded-md flex items-center justify-center flex-col'>
                        <div>
                            <div className=''>
                                <MdError size={60} className='text-teal mx-auto'/>
                            </div>
                            <h2 className='text-xl lg:text-2xl font-semibold mt-10'>A new version is available 🥳🎉</h2>
                            <p className='mt-3 mb-5 md:w-72 mx-auto text-center'>Hold on, page will refresh & update now.</p>
                        </div>
                    </div>
                </div>
    }
    return <ErrorMessage/>
}

export default ErrorBoundary