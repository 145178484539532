import { SidebarInterface } from "../typings/Shared"

export const userData = JSON.parse(localStorage.getItem('userData')!)
export const userGroups = JSON.parse(localStorage.getItem('userGroups')!)
export const correlationId = localStorage.getItem('correlationId')!

export const queryTypeStyles = {
    'ChangeOfName': 'bg-lightGreen text-green',
    'NameSubstitution': 'bg-lightGreen text-green',
    'MissingProficiencyCertificate': 'bg-lightGreen text-green',
    'ChangeOfAddress': 'bg-lightRed text-red',
    'ChangeOfProprietorDetails': 'bg-lightRed text-red',
    'MissingFilingPayment': 'bg-lightRed text-red',
    'ChangeOfID': 'bg-lightPurple text-purple',
    'ChangeOfDateOfBirth': 'bg-lightPurple text-purple',
    'ChangeOfGender': 'bg-lightPurple text-purple',
    'ChangeOfDescription': 'bg-lightYellow text-yellow',
    'ChangeOfSignature': 'bg-lightRed text-red',
    'ChangeOfPassport': 'bg-lightBlue text-blue',
    'ChangeOfNatureOfBusiness': 'bg-lightBlue text-blue',
    'Others': 'bg-lightBlue text-blue',
}

export const applicationTypes = [
    {
        label: 'CompanyIncorporation',
        value: 'CompanyIncorporation',
    },
    {
        label: 'BusinessNameRegistration',
        value: 'BusinessNameRegistration',
    },
]

export const navLinks:SidebarInterface[] = [
    {
        title: 'Applications',
        icon: <img src='/icons/Category.svg' alt='category-icon' className='w-5 h-5'/>,
        links: [
            {
                title: 'Offline applications',
                link: '/offline'
            },
            {
                title: 'Dead letter applications',
                link: '/deadletter'
            },
            {
                title: 'All applications',
                link: '/applications'
            },
            {
                title: 'At risk applications',
                link: '/riskApplications'
            },
            {
                title: 'Query management',
                link: '/queryManagement'
            },
        ]
    },
    {
        title: 'Opex',
        icon: <img src='/icons/Star.svg' alt='star-icon' className='w-5 h-5'/>,
        links: [
            {
                title: 'Pipeline',
                link: '/opex/pipeline'
            },
            {
                title: 'Metrics',
                link: '/opex/metrics'
            },
        ]
    },
    {
        title: 'Partners',
        icon: <img src='/icons/User.svg' alt='user-icon' className='w-5 h-5'/>,
        links: [
            {
                title: 'Partner list',
                link: '/partners'
            },
        ]
    },
    {
        title: 'Operations',
        icon: <img src='/icons/User.svg' alt='user-icon' className='w-5 h-5'/>,
        links: [
            {
                title: 'Operations list',
                link: '/operations'
            }
        ]
    },
    {
        title: 'Reporting',
        icon: <img src='/icons/Document.svg' alt='user-icon' className='w-5 h-5'/>,
        links: [
            {
                title: 'Revenue Tracker',
                link: '/revenue'
            },
            {
                title: 'Applications report',
                link: '/reporting'
            },
            {
                title: 'Operations report',
                link: '/operationsReporting'
            },
            {
                title: 'Payment Bot report',
                link: '/paymentBotReporting'
            },
            {
                title: 'Customer list',
                link: '/customers'
            },
        ]
    },
    {
        title: 'Settings',
        icon: <img src='/icons/Setting.svg' alt='settings-icon' className='w-5 h-5'/>,
        links: [
            {
                title: 'Pricing',
                link: '/pricing'
            },
            {
                title: 'Groups',
                link: '/groups'
            },
            {
                title: 'Holidays',
                link: '/holidays'
            },
            {
                title: 'Assignments Config.',
                link: '/assignments'
            },
            {
                title: 'Validations',
                link: '/validations'
            },
        ]
    },
]

export const booleanOptions = [
    {
        label: 'Yes',
        value: true
    },
    {
        label: 'No',
        value: false
    },
]