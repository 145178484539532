import { useEffect, useState } from 'react'
import { IApplicationTab } from '../../../utils/typings/Shared'


const ApplicationTab = ({ tabs, onClick, setactiveComponentTab, activeComponentTab }:IApplicationTab) => {
    
    const [activeTab, setActiveTab] = useState<string>(activeComponentTab ?? tabs[0]?.label)

    useEffect(() => {
        if(activeComponentTab){
            setActiveTab(activeComponentTab!)
            setactiveComponentTab(activeComponentTab)
        }
    }, [activeComponentTab])

    
    const handleClick = (value: string) => {
        onClick && onClick(value)
        setActiveTab(value)
        setactiveComponentTab(value)
    }


    return (
        <div className='flex flex-wrap items-center gap-5'>
            {
                tabs.map(tab => (
                    <div
                        key={tab.value}
                        className={`${(activeTab === tab.value || activeTab === tab.label) && 'bg-black text-white rounded-md px-10 py-3'} text-sm cursor-pointer`} 
                        onClick={() => handleClick(tab.value)}
                    >
                        { tab.label }
                    </div>
                ))
            }
        </div>
    )
}

export default ApplicationTab