import { BadgeInterface } from '../../../utils/typings/Shared'

const InProgressStatus = [
    'InProgress', 'PartnerAssigned', 'CompletedPartner',
    'Medium', 'Undecided', 'PENDING', 'Pending',
    'Processing', 'Queried', 'Draft', 'InCorporated',
    'UnResolved', 'QueriedResponded', 'QueryResponded',
    'Responded', 'AwaitingReview', 'AwaitingReservation',
    'AwaitingFiling', 'AwaitingPayment', 'Filed', 
    'AwaitingTIN', 'AwaitingBankAccount', 'NameReservationInProgress',
    'Suspended', 'NotResponded'
]

const ErrorStatus = [
    'error', 'High', 'Escalated', 'Rejected',
    'CloserRejected', 'CustomerNeedsHelpWithQuery'
]

const CompletedStatus = [
    'Paid', 'Completed', 'active', 'Low', 
    'Approved', 'APPROVED', 'Submitted', 
    'RegistrationCompleted', 'Resolved',
    'COMPLETED',
]

const Badge = ({ datatestid, value, status, rounded=true, className, onClick }:BadgeInterface) => {

    const background = ErrorStatus.includes(status) ? 'bg-lightRed' 
        : CompletedStatus.includes(status) ? 'bg-lightGreen' 
        : InProgressStatus.includes(status) ? 'bg-lightYellow' 
        : 'bg-lightGray'

    const color = ErrorStatus.includes(status) ? 'text-red' 
        : CompletedStatus.includes(status) ? 'text-green' 
        : InProgressStatus.includes(status) ? 'text-yellow' 
        : 'text-black'
    
    return (
        <div data-testid={datatestid} onClick={onClick} className={`${background} ${color} ${rounded ? 'rounded-3xl' : 'rounded-md'} ${className} px-4 py-2 items-start cursor-pointer`}>{ value }</div>
    )
}

export default Badge