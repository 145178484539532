import { CiEdit } from "react-icons/ci"
import { AllQueryDocumentTypes } from "../typings/Applications"

export const applicationDetailsTabs = [
    {
        label: 'Details',
        value: 'Details'
    },
    {
        label: 'Process',
        value: 'Process'
    },
    {
        label: 'Queries',
        value: 'Queries'
    },
    {
        label: 'Bot History',
        value: 'Bot History'
    },
    {
        label: 'Documents',
        value: 'Documents'
    },
    {
        label: 'CAC Credentials',
        value: 'CAC Credentials'
    },
    
]

export const clientApplicationTabs = [
    {
        label: 'Applications',
        value: 'Applications'
    }
]

export const queryPriorities = [
    {
        label: 'High',
        value: 'High'
    },
    {
        label: 'Medium',
        value: 'Medium'
    },
    {
        label: 'Low',
        value: 'Low'
    }
]

export const triggerOptions = [
    {
        value: 'nameReservationOnly',
        label: 'Name Reservation Only'
    },
    {
        value: 'nameReservationAndFiling',
        label: 'Name Reservation & Filing'
    }
]
export const allTriggerOptions = [
    {
        value: 'nameReservationOnly',
        label: 'Name Reservation Only'
    },
    {
        value: 'nameReservationAndFiling',
        label: 'Name Reservation & Filing'
    },
    {
        value: 'filingOnly',
        label: 'Filing Bot Only'
    },
]

export const filingTriggerOption = [
    {
        value: 'filingOnly',
        label: 'Filing Bot Only'
    },
]

export const reservationTriggerOption = [
    {
        value: 'nameReservationOnly',
        label: 'Name Reservation Only'
    },
]

export const QueryDocumentObjectTypes = [
    {
        label: 'ShareHolder',
        value: 'ShareHolder'
    },
    {
        label: 'Director',
        value: 'Director'
    },
    {
        label: 'SoleProprietor',
        value: 'SoleProprietor'
    },
    {
        label: 'Partner',
        value: 'Partner'
    },
]


export const documentTypes = [
    {
        label: 'ID',
        value: 'ID'
    },
    {
        label: 'Signature',
        value: 'Signature'
    },
    {
        label: 'Passport',
        value: 'Passport'
    }
]

export const QueryDocumentTypes:AllQueryDocumentTypes = {
    'Trademark': [
        {
            label: 'User',
            value: 'User',
        }
    ],
    'CompanyIncorporation' : [
        {
            label: 'ShareHolder',
            value: 'ShareHolder'
        },
        {
            label: 'Director',
            value: 'Director'
        }
    ],
    'CompanyIncorporationShareholder' : [
        {
            label: 'ShareHolder',
            value: 'ShareHolder'
        },
    ],
    'CompanyIncorporationDirector' : [
        {
            label: 'Director',
            value: 'Director'
        }
    ],
    'BusinessNameRegistration': [
        {
            label: 'SoleProprietor',
            value: 'SoleProprietor'
        },
        {
            label: 'Partner',
            value: 'Partner'
        }
    ],
    'BusinessNameRegistrationSoleProprietor': [
        {
            label: 'SoleProprietor',
            value: 'SoleProprietor'
        }
    ],
    'BusinessNameRegistrationPartner': [
        {
            label: 'Partner',
            value: 'Partner'
        }
    ],
}


export const actionOptions = [
    {
        title: 'Edit Application',
        icon: <CiEdit size={22}/>,
        tab: 'edit'
    },
    {
        title: 'Change Application Status',
        icon: <img src='/icons/TickSquare.svg' className='w-4 h-4'/>,
        tab: 'ChangeStatus'
    },
    {
        title: 'Add Payment Details',
        icon: <img src='/icons/Wallet.svg' className='w-4 h-4'/>,
        tab: 'Payment'
    },
]


