import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { toast } from 'react-toastify';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useLocation, useParams } from 'react-router-dom';
import ModalWrapper from '../../shared/ModalWrapper';
import { ModalInterface } from '../../../../utils/typings/Applications';
import { updateApplicationStatus } from '../../../api/Applications';
import Button from '../../ui/Button';
import Loader from '../../ui/Loader';
import Input from '../../form/TextInput';
import { useApplicationStatusData } from '../../../hooks/useInfoQuery';


const schema = yup.object().shape({
    status: yup.string()
});
function ChangeApplicationStatus({ open, onClose }:ModalInterface) {

    let { id } = useParams();
    const location = useLocation()
    const queryClient = useQueryClient()
    

    const { control, watch, reset, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            status: ''
        }
    });
    

    const {data, isLoading, isError} = useApplicationStatusData({ enable: open })


    const mutation = useMutation({
        mutationFn: (data:any) => updateApplicationStatus(data),
        onError: () => {
            toast.error("Application status update failed", {
                toastId: "status-update-unsucessful"
            });
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['application-details'] })
            queryClient.invalidateQueries({ queryKey: ['applications'] })
            toast.success("Application status update successfull", {
                toastId: "status-update-sucessful"
            });
            reset()
            onClose()
        },
    })

    
    let status = watch('status')


    const handleStatusChange = () => {
        const data = {
            applicationId: id,
            email: location?.state?.customerEmail,
            status: status
        }
        if(status !== ''){
            mutation.mutate(data)
        }
        else {
            toast.warning(`Please select a status }`, {
                toastId: "missing-selected-status"
            });
        }
    }

    if(!open) return null
    return (
        <ModalWrapper onClose={onClose}>
            <div className='flex justify-between items-center mt-10'>
                <div className=''>
                    <h3 className='font-semibold text-lg'>Change Status</h3>
                    <p className='text-gray text-sm'></p>
                </div>
                <Button onClick={handleStatusChange}>
                    {
                        mutation.isLoading ? <Loader color='stroke-white'/> : 'Update'
                    }
                </Button>
            </div>
            {
                isLoading && <Loader className='my-9'/>
            }
            {
                data && !isError && !isLoading &&
                <div className='divide-y divide-lightGray mt-5 overflow-y-auto'>
                    {
                        data?.map((value: string, index:number) => (
                            <div key={value} className='py-5 flex items-center justify-between'>
                                <div>
                                    <h3>{ value }</h3>
                                    {/* <p className='text-sm text-gray'>{ value?.emailAddress }</p> */}
                                </div>
                                <Controller
                                    name="status"
                                    control={control}
                                    render={({ field }) => <Input {...field} value={value} errors={errors} type='radio' className='w-full pl-6 py-3' />}
                                />
                            </div>
                        ))
                    }
                </div>
            }
            
        </ModalWrapper>
    )
}

export default ChangeApplicationStatus