import axios, { InfraAxiosInstance } from '../../utils/AxiosConfig';
import { UserProfile } from '../../utils/typings/Shared';

export const fetchApplicationStatuses = async (): Promise<any> => {
    let response = await axios.get(`parm/application-statuses`, {
        headers: {
            ApiTemplate: '/parm/application-statuses',
            StartTime: Date.now()
        }
    })
    return response.data
};

export const fetchCurrentUserProfile = async (): Promise<UserProfile> => {
    let response = await axios.get(`User/get-current-user-profile`, {
        headers: {
            ApiTemplate: '/User/get-current-user-profile',
            StartTime: Date.now()
        }
    })
    localStorage.setItem('userData', JSON.stringify(response.data.result))
    return response.data.result
};

export const fetchUserById = async ({ userId }: { userId: string }): Promise<any> => {
    return axios.get(`User/V2/${userId}`, {
        headers: {
            ApiTemplate: '/User/V2/${userId}',
            StartTime: Date.now()
        }
    })
    .then(response => {
        return response.data.result
    }).catch((error:{ response: { data: { message: string }}}) => {
        return Promise.reject(new Error(error.response.data.message))
    })
};

export const fetchGroups = async (): Promise<any> => {
    let response = await axios.get(`parm/get-user-groups`, {
        headers: {
            ApiTemplate: '/parm/get-user-groups',
            StartTime: Date.now()
        }
    })
    localStorage.setItem('groups', JSON.stringify(response.data.result))
    return response.data.result
};

export const fetchApplicationTypes = async (): Promise<any> => {
    let response = await axios.get(`parm/application-types`, {
        headers: {
            ApiTemplate: '/parm/application-types',
            StartTime: Date.now()
        }
    })
    return response.data
};

export const fetchPartnerTypes = async (): Promise<any> => {
    let response = await axios.get(`parm/partner-types`, {
        headers: {
            ApiTemplate: '/parm/partner-types',
            StartTime: Date.now()
        }
    })
    return response.data
};

export const fetchCountries = async (): Promise<any> => {
    let response = await InfraAxiosInstance.get(`parm/countries`, {
        headers: {
            ApiTemplate: '/parm/countries',
            StartTime: Date.now()
        }
    })
    return response.data
};

export const fetchSlaTypes = async (): Promise<any> => {
    let response = await axios.get(`parm/norebase-sla-types`, {
        headers: {
            ApiTemplate: '/parm/norebase-sla-types',
            StartTime: Date.now()
        }
    })
    return response.data
};


export const fetchUserByEmail = async (email:string): Promise<any> => {
    let response = await axios.get(`User/get-user-details-by-email?email=${email}`, {
        headers: {
            ApiTemplate: '/User/get-user-details-by-email?email=${email}',
            StartTime: Date.now()
        }
    })
    return response.data
};

export const fetchReasonsForDelay = async (): Promise<any> => {
    let response = await axios.get(`Parm/delayed-reasons`, {
        headers: {
            ApiTemplate: '/Parm/delayed-reasons',
            StartTime: Date.now()
        }
    })
    return response.data
};

export const fetchPartnerCompanies = async (): Promise<any> => {
    let response = await axios.get(`Parm/assignment-config-companies`, {
        headers: {
            ApiTemplate: '/Parm/assignment-config-companies',
            StartTime: Date.now()
        }
    })
    return response.data
};

export const fetchTINOrganisationTypes = async (): Promise<any> => {
    let response = await axios.get(`TinProcess/organisation-types`, {
        headers: {
            ApiTemplate: '/TinProcess/organisation-types',
            StartTime: Date.now()
        }
    })
    return response.data.result
};

export const fetchLineOfBusiness = async (): Promise<any> => {
    let response = await axios.get(`TinProcess/line-of-business`, {
        headers: {
            ApiTemplate: '/TinProcess/line-of-business',
            StartTime: Date.now()
        }
    })
    return response.data.result
};

export const fetchTINStates = async (): Promise<any> => {
    let response = await axios.get(`TinProcess/state-lgas`, {
        headers: {
            ApiTemplate: '/TinProcess/state-lgas',
            StartTime: Date.now()
        }
    })
    return response.data.result
};

export const fetchQueryTypes = async (): Promise<any> => {
    let response = await axios.get(`Parm/query-types`, {
        headers: {
            ApiTemplate: '/Parm/query-types',
            StartTime: Date.now()
        }
    })
    return response.data
};