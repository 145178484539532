export const businessInfoToUpdate = (applicationDetails:any, cardDataState:any, data:any) => {

    //console.log(data) //variable of application details
    //console.log(cardDataState) //edited data
    //console.log(applicationDetails)

    //update outer object
    for(let key in cardDataState){
        data = {
            ...data,
            [key]: cardDataState[key]
        }
    }

    if(applicationDetails?.businessInformation || applicationDetails?.BusinessInformation){
        //update inner business information object if existing
        if(applicationDetails?.businessInformation){
            for(let key in cardDataState.businessInformation){
                data = {
                    ...data,
                    businessInformation: {
                        ...data.businessInformation,
                        [key]: cardDataState[key] ?? data.businessInformation[key]
                    }
                }
            }
        }
        else if(applicationDetails?.BusinessInformation){
            for(let key in cardDataState.BusinessInformation){
                data = {
                    ...data,
                    BusinessInformation: {
                        ...data.BusinessInformation,
                        [key]: cardDataState[key] ?? data.BusinessInformation[key]
                    }
                }
            }
        }
        
    }
    return data
}

export const companyInfoToUpdate = ({ data, updatedData }:any) => {
    if(data.companyInformation){
        data = {
            ...data,
            companyInformation: updatedData
        }
    }
    else if(data.CompanyInformation){
        data = {
            ...data,
            CompanyInformation: updatedData
        }
    }
    return data
}