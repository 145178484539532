import { createContext } from 'react';
import { DeadLetterContext, SelectableInterface } from './typings/Shared';
import { ProposedFilingQuery } from './typings/Applications';

interface AppContextInterface {
    setSelectedDeadLetterRows: ({selectedRows, allSelected, selectedCount}:SelectableInterface) => void,
    deadLetterData: DeadLetterContext,
    queryManagement: {
        queryTypes: string[]
        setQueryTypes: (value:string[]) => void,
        proposedQueryDetails: ProposedFilingQuery,
        setProposedQueryDetails: (value: ProposedFilingQuery) => void
    }
}

export const AppContext = createContext<AppContextInterface>({
    setSelectedDeadLetterRows: ({selectedRows, allSelected, selectedCount}:SelectableInterface) => {
        return 0
    },
    deadLetterData: {
		selectedRows: [], 
		allSelected: false, 
		selectedCount: 0
	},
    queryManagement: {
        queryTypes: [],
        setQueryTypes: (value: string[]) => [],
        proposedQueryDetails: {
            id: '',
            applicationId: '',
            applicationName: '',
            companyName: '',
            description: '',
            queryType: [],
            approvedBy: '',
            isApproved: false,
            dateCreated: '',
            dateUpdated: '',
            companyId: '',
            dateApproved: '',
            customerEmail: '',
            applicationQueryIds: [],
            applicationQueryCount: 0,
            approvedQueryTypes: []
        },
        setProposedQueryDetails: (value: ProposedFilingQuery) => []
    }
});
