import { Link } from 'react-router-dom'

const HomePage = () => {
    return (
        <div className='px-6 xl:px-14 my-10 '>
            <h4 className='mb-10 font-bold text-3xl'>Quick Access:</h4>
            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4'>
                <Link to='/applications'>
                    <div className='border border-lightGray rounded-md p-4 flex h-36 justify-center items-center cursor-pointer hover:bg-lighterGray hover:border-0 hover:font-bold'>
                        <h3>All Applications</h3>
                    </div>
                </Link>
                <Link to='/queryManagement'>
                    <div className='border border-lightGray rounded-md p-4 flex h-36 justify-center items-center cursor-pointer hover:bg-lighterGray hover:border-0 hover:font-bold'>
                        <h3>Query management</h3>
                    </div>
                </Link>
                <Link to='/pricing'>
                    <div className='border border-lightGray rounded-md p-4 flex h-36 justify-center items-center cursor-pointer hover:bg-lighterGray hover:border-0 hover:font-bold'>
                        <h3>Pricing management</h3>
                    </div>
                </Link>
            </div>
        </div>
    )
}

export default HomePage