import { contentTypeStrings } from "../constant"
import { DownloadDocumentInterface } from "../typings/Applications"

export const handleFilesWithOctetStream = (data:DownloadDocumentInterface) => {
    if(data.contentType === 'application/octet-stream'){
        const type = data.fileName.substring(data.fileName.length - 3)
        if(contentTypeStrings.includes(type)){
            if(data.fileName[data.fileName.length - 4] !== '.'){
                data.fileName = data.fileName.slice(0, -3) + '.' + type
                data.contentType = `application/${type}`
            }
        }
        else{
            data.fileName = data.fileName.slice(0, -4) + '.' + type
            data.contentType = `application/jpeg`
        }
    }
    return data
}

export const handleMultipleFileDownloads = (value:DownloadDocumentInterface, setPdfSources:any, setImgSources:any) => {
    const type = value?.contentType!?.split('/')[1]
    let fileType = ''
    let docData = {
        file: value.file,
        fileName: value.fileName,
        url: value.url,
        contentType: value.contentType,
        data: ''
    }
    if(type === 'octet-stream'){
        const typeFromFile = value.fileName.substring(value.fileName.length - 3)
        if(contentTypeStrings.includes(typeFromFile)){
            fileType = typeFromFile
        }
        else{
            fileType = 'jpeg'
        }
    }
    if(fileType === 'pdf' || type === 'pdf'){
        docData = {
            ...docData,
            data: `${value.file}`,
        }
        setPdfSources((prev: DownloadDocumentInterface[]) => ([
            ...prev,
            docData
        ]))
    }
    else {
        docData = {
            ...docData,
            data: `data:image/${type};base64,${value.file}`
        }
        setImgSources((prev: DownloadDocumentInterface[]) => [
            ...prev,
            docData
        ])
    }
}

export const getFileData = (data:DownloadDocumentInterface) => {
    const type = data?.contentType?.split('/')[1]
    if(type === 'pdf'){
        return `data:application/pdf;base64,${data?.file}`
    }
    else {
        return `data:image/${type};base64,${data?.file}`
    }
}